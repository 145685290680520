import { useRouter } from "next/router";
import { useEffect } from "react";

import { useSetIsSidebarMenuOpen } from "_common/hooks/global";

const ToggleSidebar = () => {
	const router            = useRouter ();
	const toggleSidebarMenu = useSetIsSidebarMenuOpen ();

	const handleRouteChange = () => {
		toggleSidebarMenu ( false );
	};

	useEffect ( () => {
		router.events.on ( "routeChangeComplete", handleRouteChange );

		// If the component is unmounted, unsubscribe
		// from the event with the `off` method:
		return () => {
			router.events.off ( "routeChangeComplete", handleRouteChange );
		};
	}, [] );

	return null;
};

export default ToggleSidebar;
